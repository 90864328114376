.App {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /*   grid-template-rows: repeat(auto-fill, 1fr); */
  /*   grid-auto-flow: column; */
  padding: 20px;
  gap: 10px;
}

.App > div {
  align-self: center;
}

::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 600px) {
}
