.Settings-page {
  padding: 32px 38px;
}

.Settings-options-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 30px;
  margin-top: 24px;
}

.Settings-nav-option {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--color-accent-05);
  width: 400px;
  aspect-ratio: 2/1;
  border-radius: 10px;
  gap: 8px;
  cursor: pointer;
}

.Settings-nav-option:hover {
  background: var(--color-accent-gradient);
  color: #fff;
}

.Api-integration-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 32px 28px;
  gap: 20px;
  margin: auto;
  width: 660px;
}

.Api-integration-brief {
  padding: 10px 20px 20px;
  border: solid 2px var(--color-pb-05-solid);
  border-radius: 8px;
  /*   width: 400px; */
  /*   display: inline-table;
    margin-right: 20px;
    margin-top: 10px; */
}

.Api-integration-brief:first-child {
  margin: 0;
}

.Api-integration-header {
  padding: 24px;
  border: solid 2px var(--color-pb-05-solid);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.03);
}

.Page {
}
