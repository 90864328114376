.App-layout {
  height: var(--full-viewport-height);
  display: flex;
  column-gap: 24px;
  padding: 30px;
  background-color: var(--color-brand-accent-light);
}

.App-outlet {
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  overflow-y: scroll;
}

@media screen and (max-width: 600px) {
  .App-layout {
    padding: 0;
  }
}

.Dashboard-menu-header {
  border-bottom: solid 1px var(--color-pb-10-solid);
  text-align: center;
  padding-bottom: 22px;
}

.Dashboard-menu-header.condensed {
  padding-bottom: 16px;
}

.Dashboard-menu-header > .SocialPi-logo {
  width: 116px;
}

.Dashboard-menu-header.condensed > .SocialPi-logo {
  width: 66px;
}

.Dashboard-menu {
  list-style: none;
  background-color: #fff;
  padding: 22px 28px;
  border-radius: 12px;
  border: 1px solid var(--color-pb-10-solid);

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Dashboard-menu-button.disabled {
  opacity: 40%;
  cursor: default !important;
}

.Dashboard-menu-button > * {
  cursor: pointer;
}

.Dashboard-menu-button {
  color: var(--color-pb);
  font-size: var(--font-size-H3);
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 20px;
  border-radius: 8px;
}

.Dashboard-menu-button:visited {
  color: var(--color-pb);
}

.Dashboard-menu-button.link-pending {
  opacity: 50%;
}

.Dashboard-menu-button.link-active {
  background: var(--color-brand-accent-light);
}

.Dashboard-page {
  padding: 32px;
  display: grid;
  grid-template-rows: repeat(4, fit-content(500px));
  grid-template-columns: 3fr 2fr 50%;
  gap: 12px;
}

@media screen and (max-width: 600px) {
  .Dashboard-page {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }
}

.Dashboard-home-header {
  display: grid;
  grid-template-columns: 60px auto auto;
  grid-template-rows: 60px;
  align-items: center;
  gap: 16px;
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  margin-bottom: 12px;
}

.Dashboard-home-header > .Store-logo {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  object-fit: contain;
}

.Dashboard-home-header > .Dropdown-container {
  width: 160px;
  justify-self: flex-end;
}

.Dashboard-home-header > .Dropdown-container > div {
  background-color: var(--color-pb-05-solid);
  box-shadow: none;
}

.Stat-card {
  grid-row: 2 / span 1;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: fit-content(50%) fit-content(50%);
  align-items: center;
  gap: 18px 12px;
  padding: 30px 16px;
  border: 1px solid var(--color-pb-10-solid);
  border-radius: 8px;
  height: fit-content;
}

.Stat-card > .H3 {
  grid-row: 1 / span 1;
  grid-column: 1 / span 2;
}

.Stat-card > .H2 {
  grid-row: 2 / span 1;
  grid-column: 1 / span 1;
  font-weight: 500;
  font-size: calc(0.5vw + 12px);
}

.Stat-card > .Trend {
  grid-column: 2 / span 1;
}

.Trend-green {
  background-color: var(--color-green-10-solid);
  color: var(--color-green-solid);
}

.Trend-red {
  background-color: var(--color-red-10-solid);
  color: var(--color-red-solid);
}

.Revenue-stat {
  grid-column: 1 / span 1;
}

.Item-sold-stat {
  grid-column: 2 / span 1;
}

.Item-sold-stat > h3 {
  color: #f57d0e;
}

.Dashboard-page > .Commission-banner {
  background-color: var(--color-brand-accent);
  color: #fff;
  grid-row: 3 / span 1;
  grid-column: 1 / span 2;
  width: 100%;
  height: fit-content;
  padding: 12px 32px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Dashboard-page > .Product-list {
  grid-row: 4 / span 1;
  grid-column: 1 / span 3;
  background-color: #f8f9fe;
  min-height: 50vh;
  border-radius: 8px;
  margin-top: 16px;
  height: fit-content;
}

@media screen and (max-width: 600px) {
  .Dashboard-page > .Product-list {
    margin: 16px -16px;
    width: calc(100% + 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
}

.Dashboard-page > .Product-list > ul > li:first-child {
  padding-left: 12px;
}

.Dashboard-page > .Revenue-chart {
  grid-row: 1 / span 3;
  grid-column: 3 / span 1;
  margin-left: 18px;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
