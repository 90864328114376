.Store-details-page {
  width: clamp(600px, 70%, 960px);
  margin: auto;
  padding: 32px 0;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .Store-details-page {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.Store-details-banner {
  width: 60%;
  aspect-ratio: 2/1;
  object-fit: cover;
  object-position: center;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
}

.Store-details-logo,
.Store-logo-upload-placeholder {
  width: 160px;
  aspect-ratio: 1/1;
  object-fit: contain;
  object-position: center;
  border-radius: 100%;
  border: solid 16px #fff;
  z-index: 1;
  position: relative;
  display: block;
  margin: -72px auto 0 auto;
  background-color: var(--color-pb-05-solid);
}

.Store-details-logo {
  /*   outline: solid 2px var(--color-accent-05-solid); */
  width: 120px;
  box-shadow: 2px 2px 6px 2px var(--color-pb-03);
}

.Store-details-form {
  width: 480px;
  margin: auto;
  text-align: left;
  margin-top: 40px;
}

@media screen and (max-width: 600px) {
  .Store-details-form {
    width: 100%;
    padding: 0;
  }

  .Store-details-banner {
    width: 100%;
  }

  .Store-details-logo,
  .Store-logo-upload-placeholder {
    border: solid 6px #fff;
  }
}

.Store-details-form > div {
  margin-top: 16px;
}

/*#region PRODUCTS*/

/*#region LISTING*/
.Product-list-empty {
  text-align: center;
  width: 40%;
  margin: auto;
  margin-top: 15vh;
  border: dashed 2px var(--color-pb-40);
  padding: 25px 20px;
  border-radius: 4px;
}

.Product-listing-page {
  padding: 32px;
  height: 100%;
}

.Product-listing-page-header {
  display: flex;
  column-gap: 20px;
  row-gap: 12px;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
  .Product-listing-page-header > button {
    width: 100%;
    flex-direction: row;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.Product-listing-table-container {
  padding: 24px 32px;
  background: #f8f9fe;
  border-radius: 8px;
  margin-top: 48px;
  min-height: calc(100% - 110px);
}

.Product-listing-table-header {
  display: flex;
  gap: 24px;
  align-items: center;
}

.Product-listing-table {
  list-style: none;
  margin-top: 24px;
}

.Product-listing-row {
  display: grid;
  grid-template-columns: 2.5fr 1.5fr 1fr 1fr 1fr 1fr 0.5fr;
  align-items: center;
  column-gap: 12px;
}

@media screen and (max-width: 600px) {
  .Product-listing-page {
    padding: 32px 0px 0 0;
  }
  .Product-listing-table-container {
    padding: 16px;
  }
  .Product-listing-row {
    grid-template-columns: 2.5fr 1fr;
  }
  .Product-listing-row > * {
    border-right: none !important;
  }

  .Product-listing-row > :nth-child(2) {
    text-align: right !important;
  }
  .Product-listing-page-header {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.Product-listing-row:first-child {
  border-bottom: solid 1px var(--color-pb-10);
}

.Product-listing-row:first-child > * {
  width: 100%;
  border-right: none;
  font-weight: 500;
  align-self: start;
  padding: 0 12px 12px 0;
}

.Product-listing-row:first-child > h2:first-child {
  text-align: left;
  border-right: none;
}

.Product-listing-row:first-child > h2:last-child {
  text-align: center;
}

.Product-listing-row > h3:nth-child(2) {
  border-left: solid 1px var(--color-pb-10);
}

.Product-listing-row > * {
  padding: 12px 4px;
  border-right: solid 1px var(--color-pb-10);
}

.Product-listing-row > .Product {
  justify-self: flex-start;
  display: grid;
  grid-template-rows: repeat(2, 20px);
  grid-template-columns: 40px auto;
  column-gap: 12px;
  align-items: center;
  width: 100%;
  border-right: none;
}

.Product-listing-row > .Product > .Image {
  height: 100%;
  aspect-ratio: 1/1;
  grid-row: span 2;
  object-fit: cover;
  border-radius: 4px;
}

.Product-listing-row > .Product > .Title {
  width: 100%;
  grid-row: span 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Product-listing-row > .Toolbar {
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  border-right: none;
}
/*#endregion*/

/*#region DETAILS*/
.Product-detail-page-only-link {
  text-align: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Product-link-form {
  width: 480px;
  margin-top: 30px;
}

.Product-detail-page {
  padding: 32px 0;
  margin: auto;
  min-width: 400px;
  width: clamp(400px, 80%, 1200px);
}

@media screen and (max-width: 600px) {
  .Product-detail-page {
    min-width: 100%;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }

  .Product-detail-page > .Global-affiliate-settings-page-header {
    display: flex;
    flex-wrap: wrap;
    border-bottom: none;
  }

  .Product-detail-page > .Global-affiliate-settings-page-header > button {
    width: 48%;
  }

  .Product-detail-page > .Global-affiliate-settings-page-header > .H1 {
    min-width: 80%;
  }
}

.Product-detail-forms-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  justify-content: center;
  gap: 20px;
  margin-top: 26px;
}

@media screen and (max-width: 600px) {
  .Product-detail-forms-grid {
    display: flex;
    flex-direction: column-reverse;
    /* grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); */
    /* justify-content: center; */
    /*  gap: 20px; */
    margin-top: 26px;
  }

  .Product-detail-forms-grid > div > .Affiliate-settings-form {
    border: 1.4px solid var(--color-pb-05);
    padding: 20px 12px;
  }
}

.Product-detail-form {
  box-shadow: 0px 8px 20px 0px var(--color-pb-03);
  border-radius: 12px;
  border: 1.4px solid var(--color-pb-10);
  padding: 24px;
}

.Product-details-form-button {
  border-top: 1px solid var(--color-pb-03);
  text-align: right;
  margin-top: 20px;
  padding-top: 12px;
}

.Product-details-form-button > button {
  display: inline-flex;
  width: 40%;
  max-width: 200px;
}

.Product-details-form-button > button:first-child {
  margin-right: 8px;
}

@media screen and (max-width: 600px) {
  .Product-details-form-button {
    width: 100%;
    border-top: 1px solid var(--color-pb-03);
    text-align: center;
    margin-top: 20px;
    padding-top: 12px;
  }

  .Product-details-form-button > button {
    display: inline-flex;
    width: 48%;
  }

  .Product-details-form-button > button:first-child {
    margin-right: 8px;
  }
}

.Product-details-media-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column dense;
  gap: 12px;
}

.Product-details-media {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
}

.Product-details-media:first-child {
  grid-column: span 2;
  grid-row: span 2;
}

.Product-details-media:hover {
  opacity: 40%;
  filter: grayscale(100%);
}

.Product-details-media-upload {
  grid-column: span 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  font-size: var(--font-size-BT2);
  font-weight: 600;
  background-color: #fff;
  border-radius: 4px;
  border: 1px dashed var(--color-pb-60);
  cursor: pointer;
}

/*#endregion*/
/*#endregion*/

.Global-affiliate-settings-page {
  width: 660px;
  margin: auto;
  padding: 32px 0;
}

@media screen and (max-width: 600px) {
  .Global-affiliate-settings-page {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.Global-affiliate-settings-page-header {
  display: grid;
  gap: 12px;
  grid-template-columns: 24px 3fr 1fr 1fr;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .Global-affiliate-settings-page-header {
    grid-template-columns: 24px auto 1fr;
    gap: 8;
    align-items: flex-start;
    padding-bottom: 12px;
    border-bottom: solid 1px var(--color-pb-10);
  }
  .Global-affiliate-settings-page-header > .H1 {
    font-size: 18px;
  }

  .Global-affiliate-settings-page-header > button {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.Affiliate-settings-form {
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.03);
  border: 1.4px solid var(--color-pb-10);
  border-radius: 12px;
  padding: 24px;
}
@media screen and (max-width: 600px) {
  .Affiliate-settings-form {
    border: none;
    padding: 0px;
    box-shadow: none;
  }
}

.Affiliate-settings-input-group {
  border-bottom: solid 1px var(--color-pb-10);
  padding-top: 24px;
  padding-bottom: 24px;
}

.Affiliate-settings-form > .Affiliate-settings-input-group:first-child {
  padding-top: 0;
}

.Affiliate-settings-form > .Affiliate-settings-input-group:last-child {
  border: none;
}

@media screen and (max-width: 600px) {
  .Affiliate-settings-form > .Affiliate-settings-input-group:last-child {
    padding-bottom: 0px;
  }
}

.Affiliate-product-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.Affiliate-settings-input-group[data-disabled="true"] {
  opacity: 40%;
}

.Commission-rate-tip-container {
  padding: 16px 12px 20px 12px;
  background: #e8edfc;
  border-radius: 12px;
  width: calc(50% - 4px);
  margin-left: auto;
  margin-top: 8px;
}

@media screen and (max-width: 600px) {
  .Commission-rate-tip-container {
    width: 100%;
  }
}
