@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --full-viewport-height: calc(var(--vh) * 100);
  --big-page-width: clamp(600px, 70%, 960px);
  /****************************
##############################
******** Colors ************
##############################
*****************************/
  --color-pb: #1f1f1f;
  --color-pb-03: rgba(31, 31, 31, 3%);
  --color-pb-05: rgba(31, 31, 31, 5%);
  --color-pb-10: rgba(31, 31, 31, 10%);
  --color-pb-20: rgba(31, 31, 31, 20%);
  --color-pb-40: rgba(31, 31, 31, 40%);
  --color-pb-60: rgba(31, 31, 31, 60%);
  --color-pb-80: rgba(31, 31, 31, 80%);
  --color-pb-03-solid: #f8f8f8;
  --color-pb-05-solid: #f4f4f4;
  --color-pb-10-solid: #e9e9e9;
  --color-pb-20-solid: #d2d2d2;
  --color-pb-40-solid: #a5a5a5;
  --color-pb-60-solid: #797979;
  --color-pb-80-solid: #4c4c4c;

  --color-accent-05: #154ddd0d;
  --color-accent-solid: #154ddd /* #7d38ee */;
  --color-accent-05-solid: #f0f2fd /* #f9f5fe */;
  --color-accent-10-solid: #e8edfc;
  --color-accent-20-solid: #e5d7fc;

  --color-red-solid: #e84237;
  --color-red-10-solid: #fdeceb;
  --color-red-20-solid: #fad9d7;

  --color-yellow-solid: #ff6b26;
  --color-yellow-10-solid: #fffbf3;

  --color-green-solid: #0d9152;
  --color-green-10-solid: #e7f5e8;

  --color-yellow-solid: #e68717;
  --color-yellow-10-solid: #fcf2e6;

  --color-accent-gradient: linear-gradient(30deg, #154ddd 0%, #2663ff 100%)
    /* linear-gradient(30deg, #8729d1, #7252f0) */;
  --color-pi-gradient: linear-gradient(
    75deg,
    #6300d3 10%,
    #b626bf 55%,
    #eb3eb2 100%
  );

  --color-brand-accent: #154ddd;
  --color-brand-accent-light: #e8ecfe;

  /****************************
##############################
******** Buttons ************
##############################
*****************************/
  --color-button-primary: var(--color-pb);
  --color-button-secondary: var(--color-pb-05);
  --color-button-tertiary: none;

  --width-button-medium: 44px;
  --height-button-medium: 44px;

  --padding-left-button-text-medium: 24px;
  --padding-right-button-text-medium: 24px;
  --padding-right-button-text-icon-right-medium: 20px;
  --padding-left-button-text-icon-left-medium: 20px;

  /****************************
##############################
******** FONT SIZES ********
##############################
*****************************/
  --font-size-H1: 26px;
  --font-size-H2: 22px;
  --font-size-H3: 20px;
  --font-size-H4: 18px;

  --font-size-SH1: 18px;
  --font-size-SH2: 16px;
  --font-size-SH3: 14px;

  --font-size-BT1: 16px;
  --font-size-BT2: 14px;
  --font-size-BT3: 12px;

  --icon-size-small: 20px;
  --icon-size-medium: 22px;
  --icon-size-large: 26px;
}

/****************************
##############################
********* MEDIA QUERY ********
##############################
*****************************/

@media only screen and (max-width: 800px) {
  :root {
    /****************************
    ##############################
    /********* FONT SIZES ********
    ##############################
    *****************************/
    --font-size-H1: 24px;
    --font-size-H2: 20px;
    --font-size-H3: 18px;
    --font-size-H4: 16px;

    --font-size-SH1: 16px;
    --font-size-SH2: 14px;
    --font-size-SH3: 12px;

    --font-size-BT1: 14px;
    --font-size-BT2: 12px;
    --font-size-BT3: 10px;

    --icon-size-small: 18px;
    --icon-size-medium: 20px;
    --icon-size-large: 24px;

    /****************************
    ##############################
    ******** Buttons ************
    ##############################
    *****************************/
    --padding-left-button-text-medium: 20px;
    --padding-right-button-text-medium: 20px;
    --padding-right-button-text-icon-right-medium: 16px;
    --padding-left-button-text-icon-left-medium: 16px;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1200px) {
}
