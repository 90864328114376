.Ob-page {
  width: 100%;
  height: var(--full-viewport-height);
  background: linear-gradient(
    to bottom,
    var(--color-accent-solid),
    var(--color-accent-10-solid)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Ob-login-container {
  width: clamp(90vw, 90vw, 300px);
  max-width: 360px !important;
  padding: 15px;
  background-color: #fff;
  border-radius: 15px;
  padding: 16px 24px;
}

.Ob-login-heading {
  font-weight: 500;
  font-size: var(--font-size-H3);
}

.Ob-footer {
  margin-top: 28px;
  font-size: 12px;
  text-align: center;
}

.Ob-logo {
  height: 80px;
}

.Ob-logo > img {
  height: 40px;
  margin: auto;
}

.Social-signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Social-signin-container > button {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  margin-top: 28px;
  padding: 0 0.5rem 0 0;
  font-weight: 700;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  border-radius: 0.8rem !important;
}

.google-signin-btn {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.google-signin-btn > img {
  width: 40px;
  object-fit: contain;
}

.Brand-ob-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  width: clamp(600px, 70%, 960px);
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 32px 0;
}

.Brand-ob-feature-list {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  margin-top: 16px;
}

.Brand-ob-feature {
  padding: 12px;
  border-radius: 12px;
  background-color: var(--color-accent-05-solid);
}

.Brand-ob-feature > .SH2 {
  text-transform: capitalize;
}

.Ob-slider-background {
  width: 100%;
  background-size: cover;
  border-radius: 12px;
  /*   background: url("../assets/img/graphics/onboarding/affiliate_network.svg");
 background-size: 80%; */
}

.Slider {
  width: 100%;
  height: 120px;
  background-repeat: repeat-x;
  background-size: auto 120px;
}

.Slider.First {
  background-position: 0 0;
  background: url("../assets/img/graphics/onboarding/product_slider_1.png");
  animation: slider_1 infinite linear 6.5s;
  animation-delay: 0.1s;
}

.Slider.Second {
  margin-top: 24px;
  background-position: 0 0;
  background: url("../assets/img/graphics/onboarding/product_slider_2.png");
  animation: slider_2 infinite linear 6s;
  animation-delay: 0.1s;
}

@keyframes slider_1 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1008px 0;
  }
}

@keyframes slider_2 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 864px 0;
  }
}

.Brand-profile-page {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 12px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Brand-profile-input-group {
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.03);
  border: 1.4px solid var(--color-pb-10);
  border-radius: 12px;
  padding: 24px;
}

.Brand-profile-input-group {
  margin-top: 16px;
}

.Brand-profile-input-group > .Brand-profile-input-field:first-child {
  margin-top: 0px;
}

.Brand-profile-input-field {
  margin-top: 16px;
}

/* .Checkbox-input-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.Checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Input-checkbox {
  width: 16px;
  height: 16px;
  accent-color: var(--color-green-solid);
} */
