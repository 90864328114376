/****************************
##############################
******** TEXT ****************
##############################
*****************************/
.H1 {
  font-size: var(--font-size-H1);
}

.H2 {
  font-size: var(--font-size-H2);
}

.H3 {
  font-size: var(--font-size-H3);
}

.H4 {
  font-size: var(--font-size-H4);
}

.SH1 {
  font-size: var(--font-size-SH1);
}

.SH2 {
  font-size: var(--font-size-SH2);
}

.SH3 {
  font-size: var(--font-size-SH3);
}

.BT1 {
  font-size: var(--font-size-BT1);
}

.BT2 {
  font-size: var(--font-size-BT2);
}

.BT3 {
  font-size: var(--font-size-BT3);
}

/****************************
##############################
******** BUTTONS *************
##############################
*****************************/

.Btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;
  transition: border-radius linear 0.2s;
  cursor: pointer;
}

.Btn-text-link {
  color: var(--color-accent-solid);
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.Btn-primary {
  background-color: var(--color-button-primary);
  color: #fff;
}

.Btn-secondary {
  background-color: var(--color-button-secondary);
  color: var(--color-pb);
}

.Btn-tertiary {
  background-color: var(--color-button-tertiary);
  color: var(--color-pb);
}

.Btn-accent {
  background: var(--color-accent-solid);
  color: #fff;
}

.Btn-danger {
  background: var(--color-red-10-solid);
  color: var(--color-red-solid);
}

.Btn-success {
  background: var(--color-green-10-solid);
  color: var(--color-green-solid);
}

.Btn-green {
  color: var(--color-green-10-solid);
  background-color: var(--color-green-solid);
}

.Btn-disabled {
  background-color: var(--color-pb-05);
  color: var(--color-pb-40);
}

.Btn-icon {
  border-radius: 50px;
}

.Btn-icon-medium {
  width: var(--width-button-medium);
  height: var(--height-button-medium);
}

.Btn-text {
  border-radius: 32px;
}

.Btn-text-medium {
  height: var(--height-button-medium);
  padding-left: var(--padding-left-button-text-medium);
  padding-right: var(--padding-right-button-text-medium);
  font-size: var(--font-size-BT2);
}

.Btn-text-icon-left-medium {
  height: var(--height-button-medium);
  flex-direction: row-reverse;
  padding-left: var(--padding-left-button-text-icon-left-medium);
  padding-right: var(--padding-right-button-text-medium);
}

.Btn-text-icon-right-medium {
  height: var(--height-button-medium);
  padding-right: var(--padding-right-button-text-icon-right-medium);
  padding-left: var(--padding-left-button-text-medium);
}

.Btn-full {
  width: 100%;
  max-width: 480px;
}

.Btn-broad {
  background: var(--color-accent-gradient);
  padding-top: 27px;
  padding-bottom: 27px;
  color: #fff;
}

.Inline-btns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 8px;
}

/* .Btn:hover {
  border-radius: 12px;
}

.Btn-icon:hover {
  border-radius: 55px !important;
} */

.Text-label {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: var(--font-size-SH2);
  padding-left: 2px;
  margin-bottom: 8px;
}

.Input-label-with-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  margin-bottom: 16px;
}

.Input-text {
  background-color: var(--color-pb-05);
  border-radius: 12px;
  padding: 12px;
  font-size: var(--font-size-SH2);
  font-weight: 500;
  height: 44px;
  width: 100%;
}

.Input-text:focus {
  background-color: var(--color-accent-05-solid);
  border: solid 2px var(--color-accent-solid);
}

.Input-text::placeholder {
  color: var(--color-pb-60);
  font-size: var(--font-size-SH3);
}

.Input-text-error,
.Input-text-error:focus {
  background-color: var(--color-red-10-solid);
  border: solid 2px var(--color-red-solid);
}

.Input-field-static-text {
  color: var(--color-pb-60-solid);
  padding: 0 12px;
  font-weight: 500;
  font-size: var(--SH2);
  background: none;
  width: min-content;
  justify-self: flex-end;
  align-self: center;
}

.Radio-input {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: var(--font-size-SH2);
}

.Radio-input > input {
  width: 20px;
  height: 20px;
  accent-color: var(--color-green-solid);
}

.Toggle-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-pb-20);
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 44px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 50%;
}

input[type="checkbox"]:checked + .slider {
  background-color: var(--color-green-solid);
}

input[type="checkbox"]:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.Checkbox-input-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.Checkbox {
  display: flex;
  /* align-items: center; */
  gap: 8px;
}

.Input-checkbox {
  width: 25px;
  height: 25px;
  accent-color: var(--color-green-solid);
}

.Dropdown-field {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 1px solid var(--color-pb-10-solid);
  font-size: var(--font-size-SH2);
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.Dropdown-menu-list-wrapper {
  position: relative;
}

.Dropdown-menu-list {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  list-style: none;
  border-radius: 0 0 12px 12px;
  left: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: scroll;
  z-index: 1;
}

.Dropdown-menu-list-item {
  padding: 10px;
}

.Dropdown-menu-list-item:hover {
  background-color: var(--color-accent-05-solid);
}

.Tag-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 8px;
}

.Tag-list-item {
  width: fit-content;
  display: flex;
  padding: 10px 12px 10px 10px;
  align-items: center;
  border: 1px solid var(--color-pb-20);
  border-radius: 12px;
  font-size: var(--font-size-SH3);
  font-weight: 500;
}

.Error-text {
  font-size: 12px;
  color: var(--color-red-solid, #e84237);
  font-weight: 500;
  margin-top: 12px;
  padding-left: 6px;
  display: flex;
  gap: 4px;
  align-items: center;
}

.Quill-container {
  margin-top: 8px;
  height: 200px;
  background-color: var(--color-pb-05);
  border-radius: 12px;
}

#QuillEditor {
  height: calc(100% - 41px);
}

#QuillEditor {
  border: none;
}

.Quill-container.error > #QuillEditor {
  background-color: var(--color-red-10-solid);
  border: solid 2px var(--color-red-solid);
  border-top: none;
}

.Quill-container.error > .ql-toolbar.ql-snow {
  background-color: var(--color-red-10-solid);
  border: solid 2px var(--color-red-solid);
  border-bottom: none;
}

.Quill-container > .ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #e6e6e6 !important;
  border-radius: 0.5rem 0.5rem 0 0;
}

.Quill-container > .ql-container.ql-snow {
  border-radius: 0 0 0.5rem 0.5rem;
}

.Quill-container:focus-within > .ql-toolbar.ql-snow {
  background-color: var(--color-accent-05-solid);
  border: solid 2px var(--color-accent-solid) !important;
  border-bottom: 1px solid #e6e6e6 !important;
}

.ql-toolbar:active + #QuillEditor {
  border: solid 2px var(--color-accent-solid) !important;
  border-top: none !important;
  background-color: var(--color-accent-05-solid);
}

/* #QuillEditor:focus-within,  */
.Quill-container:focus-within > #QuillEditor {
  background-color: var(--color-accent-05-solid) !important;
  border: solid 2px var(--color-accent-solid) !important;
  border-top: none !important;
}

.Bottom-sheet-container {
  width: 100vw;
  height: var(--full-viewport-height);
  position: fixed;
  z-index: 13;
  display: grid;
  top: 0;
  left: 0;
}

.Bottom-sheet-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.Bottom-sheet {
  width: 375px;
  max-height: 600px;

  position: fixed;
  z-index: 11;
  align-self: center;
  justify-self: center;
  background: #fff;

  overflow-y: scroll;
  padding-bottom: 32px;
  border-radius: 14px;
}

.Bottom-sheet::-webkit-scrollbar,
.Bottom-sheet::-webkit-scrollbar-thumb {
  width: 0 !important;
  height: 0 !important;
}

.Bottom-sheet-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: var(--font-size-H2);
  font-weight: 600;
  color: var(--color-pb);
}

.Bottom-sheet > * {
  padding-left: 24px;
  padding-right: 24px;
}

.Upload-image-preview {
  object-fit: cover;
  object-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Upload-image-suggestions {
  color: var(--color-pb-60-solid);
  font-size: 12px;
  font-weight: 500;
  list-style-position: inside;
  padding-left: 6px;
}

@media only screen and (max-width: 600px) {
  .Bottom-sheet {
    width: calc(100vw - 32px);
    max-height: 85vh;
    left: 16px;
    bottom: 16px;
  }
}

.Chip {
  display: flex;
  padding: 6px 12px;
  border-radius: 8px;
  gap: 8px;
  align-items: center;
  font-size: var(--font-size-SH3);
  font-weight: 500;
}

.Section-attention {
  padding: 14px;
  background-color: var(--color-accent-05-solid);
  border-radius: 8px;
}

/*#region TIPS*/
.Tip {
  color: var(--color-pb-80);
}

.Tip.green {
  color: var(--color-green-solid);
}

.Tip.danger {
  color: var(--color-red-solid);
}

.Tip.attention {
  color: var(--color-yellow-solid);
}

.Tip.grey > .Tip-content {
  background-color: var(--color-pb-05);
}

.Tip.green > .Tip-content {
  background-color: var(--color-green-10-solid);
}

.Tip.danger > .Tip-content {
  background-color: var(--color-red-10-solid);
}

.Tip.attention > .Tip-content {
  background-color: var(--color-yellow-10-solid);
  color: var(--color-pb-80);
}

.Tip-header {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 4px;
  font-size: var(--font-size-SH2);
  font-weight: 600;
}

.Tip-content {
  padding: 16px 12px;
  border-radius: 12px;
  font-size: var(--font-size-BT1);
}
/*#endregion*/

/*#region BANNERS*/
.Banner {
  padding: 14px 20px;
  border-radius: 8px;
}

.Banner.green {
  background: var(--color-green-10-solid);
  border-left: 8px solid var(--color-green-solid, #0d9152);
}

.Banner.blue {
  background-color: var(--color-accent-10-solid);
  border-left: 8px solid var(--color-accent-solid);
}

.Banner.flex {
  display: flex;
  align-items: center;
  gap: 12px;
}

.Banner.flex.center {
  justify-content: center;
}

.Banner.flex.center > button {
  margin-left: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media screen and (max-width: 600px) {
  .Banner-container {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .Banner-container.mobile-background-blue {
    background-color: #f8fbfe;
  }

  .Banner.flex {
    flex-direction: column;
  }

  .Banner.flex > svg {
    display: none;
  }

  .Banner.flex.center {
    justify-content: center;
    align-items: flex-start;
  }

  .Banner.flex.center > button {
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
  }
}
/*#endregion*/
