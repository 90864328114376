.Affiliate-listing-page {
  padding: 38px 32px;
}

.Affiliate-list {
  border-radius: 8px;
  border: 1.4px solid var(--color-pb-10);
  padding: 24px 32px;
  background: #f8f9fe;
  width: fit-content;
}

.Affiliate-list-row {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.Affiliate-list-row > * {
  min-width: 250px;
}
