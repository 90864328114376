/****************************
##############################
******** TEXT ****************
##############################
*****************************/
.font-roboto {
  font-family: "Roboto", sans-serif;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fc-white {
  color: #fff;
}

.fc-pb {
  color: var(--color-pb);
}

.fc-pb-03 {
  color: var(--color-pb-03-solid);
}

.fc-pb-05 {
  color: var(--color-pb-05-solid);
}

.fc-pb-10 {
  color: var(--color-pb-10-solid);
}

.fc-pb-20 {
  color: var(--color-pb-20-solid);
}

.fc-pb-40 {
  color: var(--color-pb-40-solid);
}

.fc-pb-60 {
  color: var(--color-pb-60-solid);
}

.fc-pb-80 {
  color: var(--color-pb-80-solid);
}

.fc-green {
  color: var(--color-green-solid);
}

.fc-accent {
  color: var(--color-accent-solid);
}

.fc-danger-text {
  color: var(--color-red-solid);
}

.fc-success-text {
  color: var(--color-green-solid);
}

.fc-attention-text {
  color: var(--color-yellow-solid);
}

.fc-pi-gradient {
  background: var(--color-pi-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fc-accent-gradient {
  background: var(--color-accent-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-underline {
  text-decoration: underline;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.padding-8 {
  padding: 8px;
}

.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.padding-16 {
  padding: 16px;
}

.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.padding-32 {
  padding: 32px;
}

.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.margin-auto-h {
  margin-left: auto;
  margin-right: auto;
}

.heading-32 {
  font-size: 32px;
}

.width-fit-content {
  width: fit-content;
}

.height-full {
  height: 100%;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.gap-10 {
  gap: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.display-inline-flex {
  display: inline-flex;
}

.image-upload-action {
  background-color: var(--color-accent-05-solid);
  /*  z-index: 1; */
  position: relative;
  margin: auto;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.anim-container {
  padding: 0 120px;
  overflow-y: hidden;
  width: fit-content;
  height: 320px;
  display: flex;
  flex-direction: column;
}

.anim {
  background-color: #f0f0f0;
  margin-top: 20px;
  width: 300px;
  flex-basis: 80px;
  flex-shrink: 0;
  opacity: 1;
  position: relative;
  border-radius: 10px;
}

.anim {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 10px;
}

.white-box {
  width: 50px;
  aspect-ratio: 1/1;
  background-color: #fff;
  border-radius: 5px;
}

.white-line {
  width: 150px;
  height: 10px;
  background-color: #fff;
  margin-top: 5px;
  border-radius: 20px;
}

.white-line:nth-child(1) {
  width: 80px;
}

.anim:nth-child(1) {
  background: blue;
}

.anim:nth-child(2) {
  transform: scale(1.15);
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.anim:nth-child(3) {
  background: #f0f0f0;
  background: linear-gradient(
    -45deg,
    #f0f0f0 10%,
    #e4e4e4 11%,
    #e4e4e4 21%,
    #f0f0f0 22%,
    #f0f0f0 42%,
    #e4e4e4 43%,
    #e4e4e4 53%,
    #f0f0f0 54%
  );
  background-size: 800px 104px;
  height: 100px;
  position: relative;
}
