body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 3px;
  background-color: var(--color-pb-05);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--color-pb-20);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**unset**/
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
p,
h1,
h2,
h3,
button,
label,
input,
textarea,
form,
h4,
ul,
li,
a {
  box-sizing: border-box;
  outline: unset;
  border: unset;
  font-weight: unset;
  margin: unset;
  padding: unset;
  font-family: "Poppins", sans-serif;
}

textarea {
  vertical-align: top;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}
